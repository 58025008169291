@import "~bootstrap-icons/font/bootstrap-icons.css";

body {
  font-family: 'Poppins', sans-serif !important;
}

h1, h2, h3, h4, h5, h6, .btn {
  font-family: 'Poppins', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  color: #333333 !important;
}

.primary-brand-color {
  color: #012677;
}

.btn-primary {
  background-color: #012677 !important;
  border-color: #012677 !important;
  font-size: large;
}

.icon {
  font-size: 12px !important;
}

.bi.left {
  margin-right: 10px;
}

.bi.right {
  margin-left: 10px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold !important;
}

.btn {
  font-weight: 500 !important;
  padding: 0.85em 1em !important;
  letter-spacing: 0.05em;
}

p a, label a {
  text-decoration: none !important;
  font-weight: 600 !important;
  color: #012677 !important;
}

.accordion-button:not(.collapsed) {
  color: #012677 !important;
  font-weight: 600 !important;
  background-color: transparent !important;
}

@media (min-width: 1280px) {
  .container {
    max-width: 1140px !important;
  }
}

html, body { height: 100% !important;}

footer {
  position: sticky !important;
  top: 100vh !important;
}

.btn.shadow-sm {
  box-shadow: 0 .1rem .4rem rgba(0,0,0,.380)!important;
}

p, .btn {
  font-size: 1em !important;
}

footer p {
  font-size: inherit !important;
}

.list-group-item {
  background-color: #f3f3f3 !important;
}

h3, h4 {
  font-weight: 400 !important;
}

@media (max-width: 768px) {
  .accordion-button {
    font-size: 0.6em !important;
  }
}
